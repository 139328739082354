define("discourse/plugins/discourse-asana-plugin/admin/admin-dashboard-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("admin", {
      resetNamespace: true
    }, function () {
      this.route("dashboard", {
        path: "/"
      }, function () {
        this.route("admin-dashboard-international", {
          path: "plugins/asana/international",
          resetNamespace: true
        });
        this.route("admin-dashboard-ambassadors", {
          path: "plugins/asana/ambassadors",
          resetNamespace: true
        });
        this.route("admin-dashboard-leaders", {
          path: "plugins/asana/leaders",
          resetNamespace: true
        });
      });
    });
  }
});